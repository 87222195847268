@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/fontSizes.module.scss';
@import '@renderer-ui-library/base/zIndexes.scss';

.wrapper {
  position: relative;
  display: flex;
}

.mediaWrapper {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;

  & img {
    object-fit: cover;
    width: 100%;
  }
}

.elementsWrapper {
  padding: $base * 2;
  text-align: center;

  @include mediaMin('md') {
    padding: $base * 5;
  }

  .colorOverlayDark & {
    background: rgba(black, 0.5);
  }
  .colorOverlayBright & {
    background: rgba(white, 0.5);
  }
}

.desktopFieldsWrapper {
  @include mediaMax('md') {
    display: none;
  }
}

.contentWrapper {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  width: 100%;

  min-height: 60vh;

  @include mediaMin('lg') {
    min-height: 80vh;
  }

  &.narrow {
    min-height: 50vh;

    @include mediaMin('lg') {
      min-height: 60vh;
    }
  }
}

.headlines {
  margin-bottom: $base * 3;

  & h1 {
    font-size: $h4FontSize;

    @include mediaMin('md') {
      font-size: $h2FontSize;
    }
  }
}

.search {
  text-align: left;
}

.colorOverlayDark {
  background-color: rgba(black, 0.3);
}

.colorOverlayBright {
  background-color: rgba(white, 0.3);
}

.trustPilot {
  margin-top: $base * 3;
}

.mobileFilterWrapper {
  display: block;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zIndex8;
  pointer-events: none;
}

.brandLoading {
  margin-right: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobileFilterWrapperOpen {
  pointer-events: all;
}
